import React from 'react';
import { Link, graphql} from 'gatsby';
import Layout from '../components/layout';
import Pagination from "../components/Pagination"
import SEO from "../components/seo"
import ReactTypingEffect from "../vendors/react-typing-effect/src/lib";
import Slider from "react-slick";
import { Fade } from 'react-awesome-reveal';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  fade: true,
  adaptiveHeight: true
};
const servicesTemplate = ({data}) => {
return (

  <Layout>
  <SEO description="Webplace are a web design &amp; development agency based in Melbourne. We resolve business problems through customer understanding, award-winning digital web design, &amp; leading open source CMS technologies – incl. Wordpress, Joomla, &amp; Drupal. Contact our friendly team today for more information on what we can offer." title="Web Design Agency Melbourne" /> 


      <strong className="sr-only">This is main content</strong>    
      <Fade triggerOnce="true" direction="top" className="bg-decor-section has-bottom decor-fade-blue noLink">
        <div className="container py-8 py-lg-18">
          <div className="-b">
            <div className="intro mb-8">
              <h2 className="mb-4">Services we provide</h2>
              <p>We have a strong connection with open source technologies. With no propietary licence fees, your budget can be spent on tailoring a solution fit for purpose. We are experts in the following world class Content Management Systems.</p>
            </div>
          </div>
          <div className="row mb-8 mb-lg-14 -b">
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-stakeholder.svg'} width="72"  alt="" /></div>
                  <h3 className="h5 text-blue">Digital Strategy</h3>
                  <p>Delivering practical, valuable results is only possible when the project begins with appropriate groundwork and research. By understanding your goals and limitations, we’re able to create a project strategy that’s truly unique to your needs.</p>

                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-experience.svg'} width="66" height="63" alt="" /></div>
                  <h3 className="h5 text-blue">UX Design</h3>
                  <p>We strive to deliver solutions that are incredibly easy to use. Our goal is to help you simplify the complex and exceed your users’ expectations. We want to create intuitive, beautiful user experiences that are driven by research. This means we dig deep to figure out what’s working, what’s not, and where improvements to the user experience can be made.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-digital.svg'} width="66" height="63" alt="" /></div>
                  <h3 className="h5 text-blue">Web Design</h3>
                  <p>Our designers craft insight-driven solutions that make a lasting, positive impact – all while solving a business problem, not just a design problem.</p>
                 
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-technical.svg'} width="66" height="63" alt="" /></div>
                  <h3 className="h5 text-blue">Web Development</h3>
                  <p>Our team has delivered hundreds of projects across many industries utilising the latest technologies including Headless &amp; Decoupled CMS.</p>
                  <ul class="dev-list text-left pt-3 mb-10">
                  <li><a href="https://www.webplace.com.au/wordpress-developers-melbourne/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"><span class="ico-holder mr-4"> <img src="/wp.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Wordpress</span> <span class="icon icon-arrow-right position-absolute"></span> </a></li>
                  <li><a href="https://www.webplace.com.au/drupal-developers-melbourne/" class="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"><span class="ico-holder mr-4"> <img src="/drupal.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Drupal</span> <span class="icon icon-arrow-right position-absolute"></span> </a></li>
                  <li><a href="https://www.webplace.com.au/joomla-web-development/" class="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> <span class="ico-holder mr-4"><img src="/joomla.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Joomla</span><span class="icon icon-arrow-right position-absolute"></span> </a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-what-we-do.svg'} width="66" height="63" alt="" /></div>
                  <h3 className="h5 text-blue">Testing & QA</h3>
                  <p>We provide robust testing across Usability, Functionality, Accessibility, Security and Performance to ensure all bases are covered prior to launch.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex mb-6">
              <div className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={'/icon-support.svg'} width="66" height="63" alt="" /></div>
                  <h3 className="h5 text-blue">Support & Maintenance</h3>
                  <p>Long after the project launches, we’ll continue to be a trusted partner that you can call on for technical support, maintenance updates, design and expert and informed advice as you need it.</p>
                  <ul class="dev-list text-left pt-3 mb-10">
                    <li><a href="https://www.webplace.com.au/wordpress-developers-melbourne/" className="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"><span class="ico-holder mr-4"> <img src="/wp.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Wordpress Support & Maintenance</span> <span class="icon icon-arrow-right position-absolute"></span> </a></li>
                    <li><a href="https://www.webplace.com.au/drupal-developers-melbourne/" class="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"><span class="ico-holder mr-4"> <img src="/drupal.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Drupal Support & Maintenance</span> <span class="icon icon-arrow-right position-absolute"></span> </a></li>
                    <li><a href="https://www.webplace.com.au/joomla-web-development/" class="position-relative d-flex flex-wrap align-items-center pl-4 pr-7 py-2"> <span class="ico-holder mr-4"><img src="/joomla.svg" alt="" width="35" height="35" /> </span> <span class="text fw-700">Joomla Support & Maintenance</span><span class="icon icon-arrow-right position-absolute"></span> </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-box bg-blue text-center text-white p-8 p-lg-10"><div className="row justify-content-center"><div className="col-md-6"> <span className="icon-bulb bobble-anim font-xl text-pink d-block mb-10"></span><h2 className="text-white">Let’s work together</h2><p>Looking for a long term digital partner to help you realise your digital goals?</p> <a href="/lets-talk" className="btn btn-primary">Request a free quote</a></div></div><div className="img-decor left-big"><img src={'/img-decor1.svg'} alt="" width="547" height="343" /></div></div>
        </div>
      </Fade>
  


  </Layout>
)}

export const pageQuery = graphql`
   query ServicePageQuery  {
      allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "folio"}}},acf: {featured_: {eq: true}}}, sort: {fields: acf___featured_ordering, order: ASC}, limit: 4) {
         edges {
            node {
                  id
                  slug
                  path
                  status
                  template
                  format
                  title
                  content
                  acf {
                   intro
                   tagline
                   client_name
                   project_name
                   featured_
                   featured_ordering
                   intro_text
                   image {
                     url {
                      localFile {
                        publicURL
                      }
                     }
                   }
                 }
                 
              }
            
         }
      }
      file(relativePath: { eq: "img01.svg"}) {
         childImageSharp {
            fluid(quality: 100, maxWidth: 4000) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`
export default servicesTemplate;